import { Space } from "antd";
import { getUserByIdApi } from "api/api.clients";
import { IRole } from "interfaces/IRole";
import { QueriesKeys } from "interfaces/queriesKeys";
import { ReactComponent as AlertTrinagle } from "public/Alert-triangle.svg";
import { useQuery } from "react-query";
import styled from "styled-components";
import { BoldCheckmark } from "t2v-icons";
import { TinyTable, Text, colors, LoaderV2, Loader } from "tap2visit-ui-kit";
import getRoleTranslate from "utils/getRoleTranslate";

import { IClient } from "../../../interfaces/IClient";
import useMatchUsers from "../hooks/useMatchUsers";
import useUserRole from "../hooks/useUserRole";
import AccessRequestStore from "../store/AccessRequest.Store";
import formatPhoneNumber from "../../../utils/format/formatPhone";

import { getSortedContacts } from "../utils/getSortedContacts";

export const ACCESS_REQUEST_COLUMN_DEFINITION_WIDTH = "26%";
export const ACCESS_REQUEST_COLUMN_DATA_WIDTH = "37%";

const AccessRequestUserTable = ({ similarObject, buildingUserRoles }: { similarObject: IClient; buildingUserRoles: IRole[] }) => {
	const item = AccessRequestStore.accessRequestItem;
	const firstName = item.client.firstName;
	const lastName = item.client.lastName;
	const middleName = item.client.middleName;
	const comparePhones = similarObject?.contacts.filter((v) => v.type === "PHONE" && !v.isDefault);
	const loginPhone = similarObject?.contacts.find((v) => v.isDefault)?.value;
	const compareEmails = similarObject?.contacts.filter((v) => v.type === "EMAIL");
	const requestEmails = item.client.emails;
	const currentRequestData = {
		email: item.client.emails.map((email) => email.value)?.join(","),
		loginPhone: item.client.phones.find((phone) => phone.isDefault)?.value,
	};

	const requestUserPhones = item.client.phones.filter((phone) => !phone.isDefault);
	const contactPhones = getSortedContacts({ requestContacts: requestUserPhones, compareContacts: comparePhones });
	const contactEmails = getSortedContacts({ requestContacts: requestEmails, compareContacts: compareEmails });

	const matchUser = useMatchUsers({
		compareUserRoles: buildingUserRoles,
		requestUser: AccessRequestStore.accessRequestItem,
		compareUser: similarObject,
	});

	const roleString = useUserRole({ clientId: item.clientId, roleId: item.roleId });
	const currentBuildingUserRoles = buildingUserRoles?.map((role) => getRoleTranslate(role.name))?.join(", ");

	const getComparerTableString = (value: string) => {
		if (similarObject) {
			if (value) {
				return value;
			}
			return "-";
		}
		return "";
	};
	const getRequestUserTableString = (value: string) => {
		if (value) {
			return value;
		}
		return "-";
	};

	const tableColumns = [
		[
			() => (
				<TableColumnsText type="table-cell-s-regular">
					Роль<span>*</span>
				</TableColumnsText>
			),
			() => (
				<MatchContainer size={5}>
					{matchUser.rolesMatch ? <CheckmarkIcon fill={colors.iconSuccessDefault} /> : <AlertTrinagle />}
					<Text type="table-cell-s-regular">{getRequestUserTableString(roleString)}</Text>
				</MatchContainer>
			),
			() => (
				<MatchContainer size={5}>
					{matchUser.rolesMatch && <CheckmarkIcon fill={colors.iconSuccessDefault} />}
					{!matchUser.rolesMatch && !!similarObject && <AlertTrinagle />}

					<Text type="table-cell-s-regular">{getComparerTableString(currentBuildingUserRoles)}</Text>
				</MatchContainer>
			),
		],
		[
			() => (
				<TableColumnsText type="table-cell-s-regular">
					Фамилия<span>*</span>
				</TableColumnsText>
			),
			() => (
				<MatchContainer size={5}>
					{matchUser.lastNameMatch ? <CheckmarkIcon fill={colors.iconSuccessDefault} /> : <AlertTrinagle />}
					<Text type="table-cell-s-regular">{getRequestUserTableString(lastName)}</Text>
				</MatchContainer>
			),
			() => (
				<MatchContainer size={5}>
					{matchUser.lastNameMatch && <CheckmarkIcon fill={colors.iconSuccessDefault} />}
					{!matchUser.lastNameMatch && !!similarObject?.lastName && <AlertTrinagle />}
					<Text type="table-cell-s-regular">{getComparerTableString(similarObject?.lastName)}</Text>
				</MatchContainer>
			),
		],
		[
			() => (
				<TableColumnsText type="table-cell-s-regular">
					Имя<span>*</span>
				</TableColumnsText>
			),
			() => (
				<MatchContainer size={5}>
					{matchUser.firstNameMatch ? <CheckmarkIcon fill={colors.iconSuccessDefault} /> : <AlertTrinagle />}

					<Text type="table-cell-s-regular">{getRequestUserTableString(firstName)}</Text>
				</MatchContainer>
			),
			() => (
				<MatchContainer size={5}>
					{matchUser.firstNameMatch && <CheckmarkIcon fill={colors.iconSuccessDefault} />}
					{!matchUser.firstNameMatch && !!similarObject?.firstName && <AlertTrinagle />}

					<Text type="table-cell-s-regular">{getComparerTableString(similarObject?.firstName)}</Text>
				</MatchContainer>
			),
		],
		[
			() => (
				<TableColumnsText type="table-cell-s-regular">
					Отчество<span>*</span>
				</TableColumnsText>
			),
			() => (
				<MatchContainer size={5}>
					{matchUser.middleNameMatch && <CheckmarkIcon fill={colors.iconSuccessDefault} />}
					{!matchUser.middleNameMatch && !!middleName && <AlertTrinagle />}

					<Text type="table-cell-s-regular">{getRequestUserTableString(middleName)}</Text>
				</MatchContainer>
			),
			() => (
				<MatchContainer size={5}>
					{matchUser.middleNameMatch && !!middleName && !!similarObject?.middleName && <CheckmarkIcon fill={colors.iconSuccessDefault} />}
					{!matchUser.middleNameMatch && !!similarObject?.middleName && <AlertTrinagle />}

					<Text type="table-cell-s-regular">{getComparerTableString(similarObject?.middleName)}</Text>
				</MatchContainer>
			),
		],
		[
			() => <TableColumnsText type="table-cell-s-regular">ИНН*</TableColumnsText>,
			() => (
				<MatchContainer size={5}>
					{matchUser.innMatch && !!item.client.inn && similarObject?.inn && <CheckmarkIcon fill={colors.iconSuccessDefault} />}
					{!matchUser.innMatch && !!item.client.inn && <AlertTrinagle />}
					<Text type="table-cell-s-regular">{getRequestUserTableString(item.client.inn)}</Text>
				</MatchContainer>
			),
			() => (
				<MatchContainer size={5}>
					{matchUser.innMatch && !!item.client.inn && similarObject?.inn && <CheckmarkIcon fill={colors.iconSuccessDefault} />}
					{!matchUser.innMatch && !!similarObject?.inn && <AlertTrinagle />}

					<Text type="table-cell-s-regular">{getComparerTableString(similarObject?.inn)}</Text>
				</MatchContainer>
			),
		],
		[
			() => <TableColumnsText type="table-cell-s-regular">Логин приложения</TableColumnsText>,
			() => {
				const showLoginAlert = matchUser.loginMatchStatus === "LOGIN_NOT_MATCH" || matchUser.loginMatchStatus === "NOT_FOUND_IN_CONTACTS";
				const showLoginCheckIcon = matchUser.loginMatchStatus === "FOUND_IN_CONTACTS" || matchUser.loginMatchStatus === "LOGIN_MATCH";

				return (
					<MatchContainer size={5}>
						{showLoginAlert && <AlertTrinagle />}
						{showLoginCheckIcon && <CheckmarkIcon fill={colors.iconSuccessDefault} />}

						<Text type="table-cell-s-regular">{formatPhoneNumber(getRequestUserTableString(currentRequestData.loginPhone))}</Text>
					</MatchContainer>
				);
			},
			() => {
				return (
					<MatchContainer size={5}>
						{matchUser.loginMatchStatus === "LOGIN_NOT_MATCH" && <AlertTrinagle />}

						{matchUser.loginMatchStatus === "LOGIN_MATCH" && <CheckmarkIcon fill={colors.iconSuccessDefault} />}
						<Text type="table-cell-s-regular">{formatPhoneNumber(getComparerTableString(loginPhone))}</Text>
					</MatchContainer>
				);
			},
		],
		...contactPhones.requestContacts.map((requestPhone, idx) => {
			const comparePhone = contactPhones.compareContacts[idx];
			const isExistComparePhoneInRequestPhones = contactPhones?.requestContacts?.find((phone) => phone.value === comparePhone?.value);
			const isExistRequestPhoneInComparePhones = item.client.phones.find((phone) => phone.value === comparePhone?.value);

			return [
				() => <TableColumnsText type="table-cell-s-regular">Телефон для связи</TableColumnsText>,
				() => (
					<MatchContainer size={5}>
						{!!isExistComparePhoneInRequestPhones && <CheckmarkIcon fill={colors.iconSuccessDefault} />}

						<Text type="table-cell-s-regular">{formatPhoneNumber(getRequestUserTableString(requestPhone.value))}</Text>
					</MatchContainer>
				),
				() => (
					<MatchContainer size={5}>
						{!!isExistRequestPhoneInComparePhones && <CheckmarkIcon fill={colors.iconSuccessDefault} />}

						<Text type="table-cell-s-regular">{formatPhoneNumber(getComparerTableString(comparePhone?.value))}</Text>
					</MatchContainer>
				),
			];
		}),
		...contactEmails.requestContacts.map((requestEmail, idx) => {
			const compareEmail = contactEmails.compareContacts[idx];
			const areEqualsEmails = requestEmail?.value && compareEmail?.value && requestEmail?.value === compareEmail?.value;
			return [
				() => <TableColumnsText type="table-cell-s-regular">Эл. почта</TableColumnsText>,
				() => (
					<MatchContainer size={5}>
						{areEqualsEmails && <CheckmarkIcon fill={colors.iconSuccessDefault} />}
						<Text type="table-cell-s-regular">{getRequestUserTableString(requestEmail?.value)}</Text>
					</MatchContainer>
				),
				() => (
					<MatchContainer size={5}>
						{areEqualsEmails && <CheckmarkIcon fill={colors.iconSuccessDefault} />}
						<Text type="table-cell-s-regular">{getComparerTableString(compareEmail?.value)}</Text>
					</MatchContainer>
				),
			];
		}),
	];

	return (
		<TinyTable
			containerWidth=""
			columnsWidth={[ACCESS_REQUEST_COLUMN_DEFINITION_WIDTH, ACCESS_REQUEST_COLUMN_DATA_WIDTH, ACCESS_REQUEST_COLUMN_DATA_WIDTH]}
			rows={tableColumns.map((item) => item.map((item) => item ?? "")) ?? []}
		/>
	);
};

export default AccessRequestUserTable;

const MatchContainer = styled(Space)`
	height: 20px;
	display: flex;
	align-items: center;
`;

const TableColumnsText = styled(Text)`
	color: ${colors.textNeutralSecondary};
	span {
		color: red;
	}
`;

const CheckmarkIcon = styled(BoldCheckmark)`
	height: 14px;
	width: 14px;
	transform: translateY(1px);
`;
