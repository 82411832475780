import React, { useState, useEffect } from "react";

import { Divider } from "antd";
import { getAllClientsApi } from "api/api.clients";
import { getClientByObjectIdApi } from "api/api.realEstate";
import CitizensDrawer from "components/Citizens/Citizens.drawer";
import Collapse from "components/Collapse/Collapse";
import { IClient } from "interfaces/IClient";
import { IRole } from "interfaces/IRole";
import { QueriesKeys } from "interfaces/queriesKeys";
import { observer } from "mobx-react-lite";
import { ReactComponent as AlignIcon } from "public/Align.svg";
import { ReactComponent as DocumentIcon } from "public/documentIcon.svg";
import { useQuery } from "react-query";
import emitter from "services/emitter";
import styled from "styled-components";
import { BoldEdit } from "t2v-icons";
import { Space, Button, Drawer, Avatar, Segmented, colors } from "tap2visit-ui-kit";

import useUserRole from "../hooks/useUserRole";
import AccessRequestStore from "../store/AccessRequest.Store";

import AccessRequestAlert from "./AccessRequestAlert/AccessRequestAlert";
import AccessRequestCollapseTable from "./AccessRequestCollapseTable";
import AccessRequestUserTable, { ACCESS_REQUEST_COLUMN_DATA_WIDTH, ACCESS_REQUEST_COLUMN_DEFINITION_WIDTH } from "./AccessRequestUserTable";
import ActionButtons from "./ActionButtons/ActionButtons";
import Skeleton from "react-loading-skeleton";
import ModalConfirmEditCitizenStore from "./ModalConfirmEditCitizen/store/ModalConfirmEditCitizen.store";

interface IAccessRequestDrawerProps {
	open: boolean;
	setOpen: (v: boolean) => void;
	setOpenChangeDrawer: (v: boolean) => void;
}

export interface IItems {
	key: string;
	label: JSX.Element;
	children: JSX.Element;
}

const AccessRequestDrawer = ({ open, setOpen, setOpenChangeDrawer }: IAccessRequestDrawerProps) => {
	const item = AccessRequestStore.accessRequestItem;
	const firstName = item.fullName?.split(" ")?.[1];
	const lastName = item.fullName?.split(" ")?.[0];
	const userInitialsText = !!firstName && !!lastName ? `${lastName?.[0]}${firstName?.[0]}` : "-";
	const [similarUsers, setSimilarUsers] = useState<IClient[] | undefined>([]);
	const [buildingUserRoles, setBuildingUserRoles] = React.useState<IRole[]>([]);
	const [clientIds, setClientIds] = useState<string[]>([]);
	const [items, setItems] = useState<IItems[]>([]);
	const [selectedComparedUserId, setSelectedComparedUserId] = React.useState<string>();

	const roleString = useUserRole({ clientId: item.clientId, roleId: item.roleId });

	const onClose = () => {
		setOpen(false);
		setSimilarUsers(undefined);
		setSelectedComparedUserId(undefined);
		setBuildingUserRoles([]);
		setItems([]);
		setClientIds([]);
	};

	const cancelRequestHandler = () => {
		onClose();
	};

	React.useEffect(() => {
		emitter.addListener("cancelAccessRequest", cancelRequestHandler);

		return () => {
			emitter.removeListener("cancelAccessRequest", cancelRequestHandler);
		};
	}, []);

	const { data: clientsListByObjectId, refetch } = useQuery({
		queryFn: () => getClientByObjectIdApi({ buildingObjectId: item.buildingObjectId }),
		queryKey: [QueriesKeys.clients],
		enabled: !!item.buildingObjectId,
	});

	const { data: clientList, isFetching: clientListIsFetching } = useQuery({
		queryFn: () => getAllClientsApi({ params: { clientIds: clientIds.join(",") } }),
		queryKey: [QueriesKeys.clients, ...(clientIds ?? [])],
		enabled: !!clientIds.length,
	});

	React.useEffect(() => {
		if (clientList) {
			const updatedUsers = similarUsers?.map((user) => {
				const updatedUser = clientList.find((currUser) => currUser.id === user.id);
				return updatedUser;
			});
			setSimilarUsers(updatedUsers);
		}
	}, [clientList]);

	useEffect(() => {
		if (item.buildingObjectId) {
			refetch();
		}
	}, [open, item]);

	useEffect(() => {
		if (clientsListByObjectId && open) {
			setClientIds(clientsListByObjectId.map((v) => v.clientId));
		}
	}, [clientsListByObjectId, open]);

	React.useEffect(() => {
		const userRoles = clientsListByObjectId?.find((v) => v.clientId === selectedComparedUserId)?.roles;

		setBuildingUserRoles(userRoles);
	}, [selectedComparedUserId]);

	useEffect(() => {
		if (item && clientList) {
			for (const user of clientList) {
				const userRoles = clientsListByObjectId.find((v) => v.clientId === user.id)?.roles;
				const isEqualRole = userRoles?.map((role) => role.id)?.includes(item.roleId);
				if (user.fullName === item.fullName && isEqualRole) {
					setSimilarUsers([user]);
					setSelectedComparedUserId(user.id);
				}
			}
		}
	}, [item, clientList]);

	React.useEffect(() => {
		const isExistCurrentSelectedUserIdInComparedList = similarUsers?.some((user) => user.id === selectedComparedUserId);

		if (!isExistCurrentSelectedUserIdInComparedList) {
			if (similarUsers?.length) {
				setSelectedComparedUserId(similarUsers[0].id);
			} else {
				setSelectedComparedUserId(undefined);
			}
			return;
		}

		if (similarUsers.length === 1 && !selectedComparedUserId) {
			setSelectedComparedUserId(similarUsers[0].id);
			return;
		}

		setSelectedComparedUserId(similarUsers[similarUsers.length - 1].id);
	}, [similarUsers]);

	useEffect(() => {
		if (clientList && open) {
			const similarUserIds = similarUsers?.map((user) => user.id);
			setItems(
				clientList.map((user) => {
					const isComparedUser = similarUserIds?.includes(user.id);
					const userRoles = clientsListByObjectId.find((v) => v.clientId === user.id)?.roles;
					const userWithRole = {
						...user,
						role: userRoles,
					};

					return {
						key: user.id,
						label: (
							<LabelWrapper>
								<AccessRequestCollapseTitle>{user.fullName}</AccessRequestCollapseTitle>
								{item.status === "NEW" && (
									<Space>
										{/* TODO Need in future */}
										{/* <Button shape="square" size="medium" typeButton="primary" icon={MessagesIcon} /> */}
										<Button
											onClick={(e) => {
												e.stopPropagation();
												ModalConfirmEditCitizenStore.visible(user);
											}}
											shape="square"
											size="small"
											typeButton="primary"
											icon={BoldEdit}
										/>
										<Button
											shape="square"
											size="small"
											typeButton={isComparedUser ? "primary" : "secondary"}
											icon={AlignIcon}
											onClick={(e) => {
												e.stopPropagation();
												if (isComparedUser) {
													setSimilarUsers((users) => users.filter((currUser) => user.id !== currUser.id));
												} else {
													setSimilarUsers((users) => [...(users ?? []), user]);
												}
											}}
										/>
									</Space>
								)}
							</LabelWrapper>
						),
						children: <AccessRequestCollapseTable item={userWithRole} />,
					};
				}),
			);
		}
	}, [clientList, open, similarUsers, item]);

	const currentSimilarUser = similarUsers?.find((user) => user?.id === selectedComparedUserId);

	return (
		<>
			<Drawer
				title="Запрос от пользователя"
				visible={open}
				onClose={onClose}
				onCancel={onClose}
				width="700px"
				showCancel={false}
				showOk={false}
				iconButtonCancel={DocumentIcon}>
				<WrapperUser>
					<WrapperUserAvatar>
						<Avatar size="40" text={userInitialsText} />
						<WrapperUserName>
							<UserName>{item.fullName}</UserName>
							<UserNameSubtitle>{roleString}</UserNameSubtitle>
						</WrapperUserName>
					</WrapperUserAvatar>
					<ActionButtons requestUser={item} onRequestSuccess={onClose} compareUserId={currentSimilarUser?.id} />
				</WrapperUser>

				<Divider />

				<AccessRequestAlert
					requestUser={AccessRequestStore.accessRequestItem}
					compareUser={currentSimilarUser}
					compareUserRoles={buildingUserRoles}
					isFetching={clientListIsFetching}
				/>

				<AccessRequestUserTableWrapper>
					<AccessRequestUserTableBorder />
					<AccessRequestWrapperTitleTable>
						<AccessRequestDrawerWidth></AccessRequestDrawerWidth>
						<AccessRequestDrawerWidth>
							<TableAvatarContainer>
								<Avatar size="32" text={userInitialsText} />
							</TableAvatarContainer>
						</AccessRequestDrawerWidth>
						{item.status === "NEW" && (
							<AccessRequestDrawerWidth>
								{similarUsers?.length > 0 && (
									<ComparedUserAvatarWrapper>
										<ComparedUserAvatarContainer>
											<Segmented
												size="contentWidth"
												items={similarUsers.map((user) => {
													const userInitials =
														!!user?.firstName && !!user?.lastName ? `${user?.lastName?.[0]}${user?.firstName?.[0]}` : "-";
													return {
														key: user.id,
														title: <Avatar size="32" text={userInitials} />,
													};
												})}
												onClick={(item) => setSelectedComparedUserId(item.key)}
												selectedKey={selectedComparedUserId}
												direction={"horizontal"}
											/>
										</ComparedUserAvatarContainer>
									</ComparedUserAvatarWrapper>
								)}
							</AccessRequestDrawerWidth>
						)}
					</AccessRequestWrapperTitleTable>

					<AccessRequestUserTable similarObject={currentSimilarUser} buildingUserRoles={buildingUserRoles} />
				</AccessRequestUserTableWrapper>

				<AccessRequestWrapperBottom>
					<AccessRequestBottomTitle>Квартира</AccessRequestBottomTitle>
					<AccessRequestBottomSubTitle>{item.addressValue}</AccessRequestBottomSubTitle>
				</AccessRequestWrapperBottom>

				{clientListIsFetching && <Skeleton height={148} />}
				{!clientListIsFetching && <Collapse accordion items={items} />}
			</Drawer>
			<CitizensDrawer />
		</>
	);
};

export default observer(AccessRequestDrawer);

const AccessRequestCollapseTitle = styled.p`
	font-weight: 700;
`;

const LabelWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const AccessRequestWrapperBottom = styled.div`
	margin: 20px 0;
`;
const AccessRequestBottomTitle = styled.p`
	font-weight: 700;
	font-size: 20px;
`;
const AccessRequestBottomSubTitle = styled.p`
	font-size: 14px;
`;

const AccessRequestDrawerWidth = styled.div`
	width: 211px;
	display: flex;
	position: relative;
	height: 100%;
`;
const AccessRequestWrapperTitleTable = styled.div`
	display: flex;
	margin: 30px 0;
	align-items: center;
`;

const AccessRequestUserTableBorder = styled.div`
	border: 2px solid ${colors.strokeDivider};
	position: absolute;
	width: calc(${ACCESS_REQUEST_COLUMN_DATA_WIDTH} + 1px); // border width
	height: 102%;
	left: calc(${ACCESS_REQUEST_COLUMN_DEFINITION_WIDTH} - 1px); // border width
	top: -2%;
	border-radius: 6px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	box-shadow: 0 4px 7px -2px #4b4b4b0f;
`;
const AccessRequestUserTableWrapper = styled.div`
	position: relative;
`;

const WrapperUser = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const UserName = styled.p`
	font-size: 14px;
	font-weight: 500;
`;
const UserNameSubtitle = styled.p`
	font-size: 12px;
	color: #12121299;
`;

const WrapperUserName = styled.div`
	margin-left: 10px;
`;

const WrapperUserAvatar = styled.div`
	display: flex;
`;

const TableAvatarContainer = styled.div`
	position: relative;
	top: 10px;
	left: -20px;
`;

const ComparedUserAvatarContainer = styled.div`
	position: absolute;
	top: 10px;
	left: 10px;
`;

const ComparedUserAvatarWrapper = styled.div`
	width: 213px;
	height: 58px;
	position: absolute;
	overflow-x: auto;
	overflow-y: hidden;
	top: -25px;
	left: -10px;
`;
