import { useEffect, useRef, useState } from "react";

import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { Avatar, Space, Button, Text, TextArea, Loader, colors } from "tap2visit-ui-kit";

import {
	createRealEstateCommentApi,
	deleteRealEstateCommentApi,
	getRealEstateCommentsApi,
	updateRealEstateCommentApi,
} from "api/api.realEstate";
import { Permissions } from "constants/constants.roles";
import type { IComments } from "interfaces/IClient";
import type { IUser } from "interfaces/User/IUser";

import { createCommentApi, deleteCommentByIdApi, getComments, updateCommentByIdApi } from "../../../api/api.clients";
import { getUserApi, getUserByIdApi } from "../../../api/api.employee";
import CitizenBuildingObjectStore from "../../../components/Citizens/store/CitizenBuildingObject.store";
import CitizensDrawerStore from "../../../components/Citizens/store/Citizens.drawer.store";
import { QueriesKeys } from "../../../interfaces/queriesKeys";
import getCommentsDate from "../utils/getCommentsDate";
import getCommentWord from "../utils/getCommentWord";

type TCommentState = {
	firstName: string;
	lastName: string;
} & IComments;

export type TCommentsType = "citizen" | "real-estate";

interface ICitizenComments {
	type: TCommentsType;
}

interface ITextWithEllipsisProps {
	isExpanded: boolean;
}

const CitizenComments: React.FC<ICitizenComments> = (props) => {
	const [commentsAll, setCommentsAll] = useState<TCommentState[]>([]);
	const [displayedComments, setDisplayedComments] = useState<TCommentState[]>([]);
	const [textComment, setTextComment] = useState("");
	const [editedComment, setEditedComment] = useState("");
	const [editingMode, setEditingMode] = useState(false);
	const [currentCommentID, setCurrentCommentID] = useState("");
	const [clickMoreButton, setClickMoreButton] = useState(false);
	const [expandedComments, setExpandedComments] = useState<Record<string, boolean>>({});
	const [shouldShowMoreButton, setShouldShowMoreButton] = useState<Record<string, boolean>>({});
	const commentRefs = useRef<Record<string, HTMLParagraphElement | null>>({});

	const isCommentEmpty = textComment.trim() === "";
	const currentCharLength = textComment.length;
	const displayedCommentsCount = 2;
	const hiddenCommentsCount = commentsAll.length - displayedCommentsCount;

	const authenticatedUser = useQuery({
		queryFn: () => getUserApi(),
	});

	const isAdmin = authenticatedUser.data.additionalProperties.role === Permissions.ADMIN;

	const { data, refetch, isFetching } = useQuery({
		queryFn: () => getComments({ params: { clientId: CitizensDrawerStore.selectedUser.id } }),
		queryKey: [CitizensDrawerStore.selectedUser, QueriesKeys.comment],
		enabled: props.type === "citizen",
	});

	const realEstateComments = useQuery({
		queryFn: () => getRealEstateCommentsApi({ params: { buildingObjectId: CitizenBuildingObjectStore.selectedBuilding.id } }),
		queryKey: [CitizenBuildingObjectStore.selectedBuilding?.id, QueriesKeys.realEstateComments],
		enabled: props.type === "real-estate",
	});

	const createComment = useMutation(createCommentApi);
	const deleteComment = useMutation(deleteCommentByIdApi);
	const updateComment = useMutation(updateCommentByIdApi);

	const createRealEstateComment = useMutation(createRealEstateCommentApi);
	const deleteRealEstateComment = useMutation(deleteRealEstateCommentApi);
	const updateRealEstateComment = useMutation(updateRealEstateCommentApi);

	useEffect(() => {
		const fetchUserInformation = async (currentData: IComments[]) => {
			const uniqueCreatedBy = Array.from(new Set(currentData.map((comment) => comment.createdBy)));
			const informationUserMap = await Promise.all(uniqueCreatedBy.map(async (userID) => await getUserByIdApi({ params: { id: userID } })));
			return informationUserMap;
		};

		const mapCommentsWithUserData = (currentData: IComments[], informationUserMap: IUser[]) =>
			currentData.map((comment) => {
				const userData = informationUserMap.find((user) => user.id === comment.createdBy);
				return {
					...comment,
					firstName: userData?.firstName || "",
					lastName: userData?.lastName || "",
				};
			});

		(async () => {
			const currentData = props.type === "citizen" ? data : realEstateComments.data;
			if (currentData) {
				const informationUserMap = await fetchUserInformation(currentData);
				const allowComments = mapCommentsWithUserData(currentData, informationUserMap);
				setCommentsAll(allowComments);
			}
		})();
	}, [data, realEstateComments.data]);

	useEffect(() => {
		if (commentsAll.length > displayedCommentsCount && !clickMoreButton) {
			setDisplayedComments(commentsAll.slice(0, 2));
		} else setDisplayedComments(commentsAll);
	}, [commentsAll]);

	useEffect(() => {
		const observers = new Set<ResizeObserver>();

		displayedComments.forEach((comment) => {
			const commentElement = commentRefs.current[comment.id];

			if (commentElement && !expandedComments[comment.id]) {
				const observer = new ResizeObserver(() => {
					if (commentElement.scrollHeight > commentElement.clientHeight) {
						setShouldShowMoreButton((prevState) => ({
							...prevState,
							[comment.id]: true,
						}));
					} else {
						setShouldShowMoreButton((prevState) => ({
							...prevState,
							[comment.id]: false,
						}));
					}
				});

				observer.observe(commentElement);

				observers.add(observer);
			}
		});

		return () => {
			observers.forEach((observer) => observer.disconnect());
		};
	}, [displayedComments, expandedComments]);

	const sentComment = async () => {
		if (props.type === "citizen") {
			createComment.mutateAsync({ body: { comment: textComment, clientId: CitizensDrawerStore.selectedUser.id } }).then(() => {
				refetch();
				setTextComment("");
			});
		}

		if (props.type === "real-estate") {
			await createRealEstateComment.mutateAsync({
				body: { comment: textComment, buildingObjectId: CitizenBuildingObjectStore.selectedBuilding.id },
			});

			realEstateComments.refetch();
			refetch();
			setTextComment("");
		}
	};

	const openEditInput = (comment: TCommentState) => {
		setCurrentCommentID(comment.id);
		setEditedComment(comment.comment);
		setEditingMode(true);
	};

	const deleteCommentFunc = async (id: string) => {
		if (props.type === "citizen") {
			await deleteComment.mutateAsync({ path: { id } });

			await refetch();
		}

		if (props.type === "real-estate") {
			await deleteRealEstateComment.mutateAsync({ path: { id } });

			await realEstateComments.refetch();
		}
	};

	const changeComment = async () => {
		if (props.type === "citizen") {
			await updateComment.mutateAsync({ path: { id: currentCommentID }, body: { comment: editedComment } });
			await refetch();
			setEditingMode(false);
			setCurrentCommentID("");
		}

		if (props.type === "real-estate") {
			await updateRealEstateComment.mutateAsync({ path: { id: currentCommentID }, body: { comment: editedComment } });
			await realEstateComments.refetch();
			setEditingMode(false);
			setCurrentCommentID("");
		}
	};

	const cancelEdit = () => {
		setCurrentCommentID(undefined);
		setEditedComment("");
		setEditingMode(false);
	};

	const showMoreComment = () => {
		setDisplayedComments(commentsAll);
		setClickMoreButton(true);
	};

	const handleToggleComment = (id: string) => {
		setExpandedComments((prevState) => ({
			...prevState,
			[id]: !prevState[id],
		}));
	};

	return (
		<>
			<Space direction="column" size={16}>
				{!isFetching &&
					displayedComments?.map((comment) => {
						const commentEditedText = comment.edited ? "(отредактировано)" : "";
						const currentEditor = comment.createdBy === authenticatedUser.data.id;

						return (
							<Container>
								<Space width="100%" align="start" justify="start">
									<Avatar firstName={comment.lastName} lastName={comment.firstName} size={"40"} />
									<Space align="start" size={6} direction="column">
										<HeaderCommentEditContainer>
											<Text type="large-medium">
												{comment.firstName} {comment.lastName}
											</Text>
											{editingMode && currentCommentID === comment.id && (
												<Text color="rgba(18, 18, 18, 0.6)" type="base-regular">
													редактирование комментария
												</Text>
											)}
										</HeaderCommentEditContainer>
										{editingMode && currentCommentID === comment.id && (
											<>
												<EditCommentContainer>
													<TextAreaStyled maxLength={255} value={editedComment} onChangeValue={(value) => setEditedComment(value)} />
												</EditCommentContainer>
												<Space justify="flex-end" width="100%">
													<Button onClick={cancelEdit} typeButton="text">
														Отменить
													</Button>
													<Button onClick={changeComment} typeButton="secondary">
														Сохранить
													</Button>
												</Space>
											</>
										)}
										{currentCommentID !== comment.id && !comment.deleted && (
											<>
												<TextWithEllipsis ref={(el) => (commentRefs.current[comment.id] = el)} isExpanded={expandedComments[comment.id]}>
													{comment.comment}
												</TextWithEllipsis>
												{(shouldShowMoreButton[comment.id] || expandedComments[comment.id]) && (
													<Button onClick={() => handleToggleComment(comment.id)} style={{ padding: 0 }} typeButton="link">
														{expandedComments[comment.id] ? "Скрыть комментарий" : "Показать весь комментарий"}
													</Button>
												)}
											</>
										)}

										{currentCommentID !== comment.id && comment.deleted && (
											<Text
												type="base-regular"
												color={colors.textNeutralPlaceholder}
												style={{ wordWrap: "break-word", wordBreak: "break-word", whiteSpace: "normal" }}>
												Комментарий удален
											</Text>
										)}
										{!editingMode && (
											<CommentActionsContainer>
												<DateTextWrapper type="base-regular" color={colors.textNeutralSecondary}>{`${getCommentsDate(
													comment.modifiedAt,
												)} ${commentEditedText}`}</DateTextWrapper>
												{!comment.deleted && (currentEditor || isAdmin) && (
													<>
														<EditButton onClick={() => openEditInput(comment)} typeButton="link">
															Редактировать
														</EditButton>
														<EditButton onClick={() => deleteCommentFunc(comment.id)} variant="red" typeButton="link">
															Удалить
														</EditButton>
													</>
												)}
											</CommentActionsContainer>
										)}
									</Space>
								</Space>
							</Container>
						);
					})}
				{commentsAll.length > displayedCommentsCount && !clickMoreButton && (
					<MoreCommentsTitle onClick={showMoreComment} typeButton="link">
						{`Показать еще ${hiddenCommentsCount} ${getCommentWord(hiddenCommentsCount)}`}
					</MoreCommentsTitle>
				)}
				{isFetching && (
					<LoaderContainer>
						<Loader />
					</LoaderContainer>
				)}
				{!isFetching && (
					<>
						<CommentsTextAreaWrapper>
							<Avatar firstName={authenticatedUser.data.lastName} lastName={authenticatedUser.data.firstName} size={"40"} />
							<TextAreaWrapper>
								<TextAreaStyled
									placeholder="Введите комментарий"
									maxLength={255}
									value={textComment}
									onChangeValue={(value) => setTextComment(value)}
								/>
								<TextAreaCharCounterWrapper>
									<Text type="small-regular">{currentCharLength}/255</Text>
								</TextAreaCharCounterWrapper>
							</TextAreaWrapper>
						</CommentsTextAreaWrapper>
						<ButtonWrapper disabled={isCommentEmpty} onClick={sentComment}>
							Отправить
						</ButtonWrapper>
					</>
				)}
			</Space>
		</>
	);
};

export default CitizenComments;

const MoreCommentsTitle = styled(Button)`
	margin-right: auto;
`;

const EditButton = styled(Button)`
	padding: 0;
`;

const ButtonWrapper = styled(Button)`
	margin-left: auto;
	margin-top: -4px;
`;
const TextAreaStyled = styled(TextArea)`
	width: 100%;
	background: white;
	resize: vertical;
	word-wrap: break-word;
	word-break: break-all;
`;
const DateTextWrapper = styled(Text)`
	// margin-right: 10px;
`;
const CommentsTextAreaWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	width: 100%;
`;
const TextAreaWrapper = styled.div`
	width: 100%;
	margin-left: 10px;
	display: flex;
	flex-direction: column;
`;

const TextAreaCharCounterWrapper = styled.div`
	color: #1212124d;
	align-self: flex-end;
`;

const LoaderContainer = styled.div`
	padding-top: 10px;
	padding-bottom: 10px;
`;

const EditCommentContainer = styled.div`
	width: 100%;
	min-width: 550px;
`;

const HeaderCommentEditContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
`;

const Container = styled.div`
	width: 100%;
	&:first-child {
		padding-top: 16px;
	}
	&:not(:nth-last-of-type(-n + 2)) {
		position: relative;
		margin-bottom: 16px;
		&::after {
			position: absolute;
			content: "";
			inset: auto 0 -16px auto;
			display: block;
			width: calc(100% - 52px);
			height: 1px;
			background-color: #12121229;
		}
	}
`;

const CommentActionsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
`;

const TextWithEllipsis = styled.p<ITextWithEllipsisProps>`
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => (props.isExpanded ? "none" : "2")};
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-word;
	overflow-wrap: break-word;
`;
