import ValidationStore, { ValidationStatuses } from "modules/validation/Validation.store";

export type TСitizenPersonalInformationValidationFields = {
	firstName: keyof typeof ValidationStatuses;
	lastName: keyof typeof ValidationStatuses;
	middleName: keyof typeof ValidationStatuses;
	residentialAddress: keyof typeof ValidationStatuses;
};

const initialValidationData: TСitizenPersonalInformationValidationFields = {
	firstName: ValidationStatuses.VALID,
	lastName: ValidationStatuses.VALID,
	middleName: ValidationStatuses.VALID,
	residentialAddress: ValidationStatuses.VALID,
};

const COMMON_REQUIRED_ERROR_MESSAGE = "Поле должно быть заполнено и содержать не более 50 символов.";

export const ERROR_MESSAGES: Record<
	keyof TСitizenPersonalInformationValidationFields,
	Partial<Record<keyof typeof ValidationStatuses, string>>
> = {
	firstName: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE },
	lastName: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE },
	middleName: { LESS_THEN: "Поле не должно содержать более 50 символов" },
	residentialAddress: { LESS_THEN: "Поле не должно содержать более 255 символов" },
};

const checkRules = {
	firstName: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.LESS_THEN]: 50,
	},
	lastName: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.LESS_THEN]: 50,
	},
	middleName: {
		[ValidationStatuses.LESS_THEN]: 50,
	},
	residentialAddress: {
		[ValidationStatuses.LESS_THEN]: 255,
	},
};

const СitizenPersonalInformationValidationStore = new ValidationStore<TСitizenPersonalInformationValidationFields>(
	initialValidationData,
	ERROR_MESSAGES,
	checkRules,
);

export default СitizenPersonalInformationValidationStore;
