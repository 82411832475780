import { MIN_TABLE_HEIGHT, MIN_TABLE_WIDTH } from "constants/constants.common";

import { useEffect, useState } from "react";
import React from "react";

import { Row } from "antd";
import PaginationContainer from "components/PaginationContainer";
import TableEmptySlug from "components/Table/TableEmptySlug";
import TableLoader from "components/Table/TableLoader";
import { useTableFilterContext } from "components/TableFilter/TableFilter.context";
import useTablePaginationLogic from "hooks/useTablePaginationLogic";
import useWindowWidth from "hooks/useWindowWidth";
import { TSortingDirection } from "interfaces/ISorting";
import { QueriesKeys } from "interfaces/queriesKeys";
import { observer } from "mobx-react-lite";
import { ReactComponent as UserChecked } from "public/userChecked.svg";
import { ReactComponent as UserDelete } from "public/userDelete.svg";
import { useQuery } from "react-query";
import SideBarStore from "store/SideBar.store";
import styled from "styled-components";
import { Button, Pagination, Table } from "tap2visit-ui-kit";
import { getOnlyDigitsString } from "utils/getOnlyDigitsString";
import { joinArrParams } from "utils/joinParams";

import { getRequestApi } from "../../../api/api.gateway";
import formatPhoneNumber from "../../../utils/format/formatPhone";
import { queryClient } from "../../../index";
import { IAccessRequestArray } from "../../../interfaces/IRequest";
import AccessRequestStore from "../store/AccessRequest.Store";
import AccessRequestRejectModalStore from "./ModalCommentRejection/store/AccessRequestRejectModal.store";
import AccessRequestTabStore from "../store/AccessRequestTab.store";

import AccessRequestDrawer from "./AccessRequestDrawer";
import Page from "components/layout/Page";

const AccessRequestTable = () => {
	const tableFilterContext = useTableFilterContext();
	const windowWidth = useWindowWidth();

	const [fullName, setFullName] = useState<string | undefined>(undefined);
	const [phone, setPhone] = useState<string | undefined>(undefined);
	const [email, setEmail] = useState<string | undefined>(undefined);
	const [open, setOpen] = useState(false);
	const [openChangeDrawer, setOpenChangeDrawer] = useState(false);
	const isMounted = React.useRef(false);

	const [sortedDateBy, setDateSortedBy] = React.useState<TSortingDirection>("asc");
	const [sortedAccountBy, setAccountSortedBy] = React.useState<TSortingDirection>("asc");

	const paginationData = useTablePaginationLogic();

	useEffect(() => {
		if (sortedDateBy || sortedAccountBy) {
			paginationData.setClientsCurrentPage(1);
		}
	}, [sortedDateBy, sortedAccountBy]);

	const requests = useQuery({
		queryFn: () =>
			getRequestApi({
				complexIds: joinArrParams(tableFilterContext.selectedComplexIds),
				buildingIds: joinArrParams(tableFilterContext.selectedBuildingsIds),
				entranceIds: joinArrParams(tableFilterContext.selectedEntrancesIds),
				floorIds: joinArrParams(tableFilterContext.selectedFloorsIds),
				fullNameSearch: fullName,
				phoneNumberSearch: getOnlyDigitsString(phone),
				emailSearch: email,
				statuses: AccessRequestTabStore.activeTab === "mobileApp" ? ["NEW"] : ["APPROVED", "CANCELED"],
				page: paginationData.clientsCurrentPage - 1,
				size: paginationData.clientsSize,
				sort: { fullName: sortedDateBy ?? "asc", accountValue: sortedAccountBy ?? "asc" },
			}),
		queryKey: [
			QueriesKeys.requests,
			tableFilterContext.selectedComplexIds,
			tableFilterContext.selectedBuildingsIds,
			tableFilterContext.selectedEntrancesIds,
			tableFilterContext.selectedFloorsIds,
			fullName,
			email,
			phone,
			sortedDateBy,
			sortedAccountBy,
			AccessRequestTabStore.activeTab,
			paginationData.clientsSize,
			paginationData.clientsCurrentPage,
		],
	});

	const isVisiblePagination = !requests.isFetching && requests.data;

	useEffect(() => {
		if (isMounted.current) {
			queryClient.invalidateQueries([QueriesKeys.requests]);
		}
		isMounted.current = true;
	}, [fullName, phone, email]);

	React.useEffect(() => {
		if (requests.data) {
			paginationData.setClientsTotal(requests.data.totalPages);
		}
	}, [requests.data?.page, fullName, phone, email]);

	const tableColumns = [
		{
			dataIndex: "date",
			onSortedBy: (v) => {
				setDateSortedBy(v ?? "asc");
			},
			key: "date",
			title: "Дата",
		},
		{
			dataIndex: "initials",
			key: "initials",
			title: "ФИО",
			onSearch: (item) => {
				setFullName(item);
			},
		},
		{
			dataIndex: "tel",
			key: "tel",
			title: "Телефон",
			onSearch: (item) => {
				setPhone(item);
			},
		},
		{
			dataIndex: "email",
			key: "email",
			title: "Эл. почта",
			onSearch: (item) => {
				setEmail(item);
			},
		},
		{
			dataIndex: "account",
			key: "account",
			title: "Лицевой счет",
			onSortedBy: (v) => {
				setAccountSortedBy(v ?? "asc");
			},
		},
		// Temporary hidden
		// {
		// 	dataIndex: "address",
		// 	key: "address",
		// 	title: "Адрес",
		// },
		// {
		// 	dataIndex: "button",
		// 	key: "button",
		// 	title: "",
		// },
	];

	const tableData = requests.data?.content?.map((v) => ({
		initials: v.fullName,
		tel: formatPhoneNumber(v.client.phones.find((phone) => phone.isDefault)?.value),
		email: v.client.emails.find((email) => email.isDefault)?.value,
		date: new Date(v.createDateTime).toLocaleDateString(),
		address: v.addressValue,
		account: v.accountValue,
		buildingObjectId: v.buildingObjectId,
		id: v.id,
		button: (
			<AccessRequestRow justify="center">
				<Button shape="square" size="medium" typeButton="primary" icon={UserChecked} disabled />
				<Button
					onClick={(e) => {
						e.stopPropagation();
						AccessRequestRejectModalStore.showModal({ requestId: v.id });
					}}
					shape="square"
					size="medium"
					typeButton="primary"
					variant="red"
					icon={UserDelete}
				/>
			</AccessRequestRow>
		),
	}));

	useEffect(() => {
		AccessRequestStore.setAccessRequestArray(tableData);
	}, [tableData]);

	return (
		<Page>
			<AccessRequestDrawer open={open} setOpen={setOpen} setOpenChangeDrawer={setOpenChangeDrawer} />
			<Table
				onRowClick={(record: IAccessRequestArray, index) => {
					const request = requests.data?.content?.[index];
					if (request) {
						AccessRequestStore.setAccessRequestItem(request);
					}
					setOpen(true);
				}}
				style={{
					width: `calc(${windowWidth}px - ${SideBarStore.sideBarWidth} )`,
					overflowX: "auto",
					minHeight: MIN_TABLE_HEIGHT,
				}}
				isFullWidth
				columns={tableColumns}
				data={tableData ?? []}
				size="m"
				emptyComponent={requests.isFetching ? TableLoader : TableEmptySlug}
			/>
			{isVisiblePagination && (
				<PaginationContainer>
					<Pagination
						onChangeSize={paginationData.setClientsSize}
						currentPage={paginationData.clientsCurrentPage}
						setCurrentPage={paginationData.setClientsCurrentPage}
						total={paginationData.clientsTotal}
						size={paginationData.clientsSize as 10 | 20 | 50 | 100}
					/>
				</PaginationContainer>
			)}
		</Page>
	);
};
const AccessRequestRow = styled(Row)`
	button:first-child {
		margin-right: 10px;
	}
`;
export default observer(AccessRequestTable);
