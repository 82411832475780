import React, { useEffect } from "react";

import dayjs from "dayjs";
import { useQuery } from "react-query";
import { type TDatePickerFilterProps, TTableColumn, showToast } from "tap2visit-ui-kit";

import { getPaymentsPageApi } from "api/api.gateway";
import { useTableFilterContext } from "components/TableFilter/TableFilter.context";
import useTablePaginationLogic from "hooks/useTablePaginationLogic";
import type { TSortingDirection } from "interfaces/ISorting";
import { QueriesKeys } from "interfaces/queriesKeys";
import { getFormtattedSum } from "utils/format/getFormtattedSum";

import TableStore from "../store/Table.store";
import { getAmount } from "../utils/getAmount";

const useTableData = () => {
	const paginationData = useTablePaginationLogic();
	const [datePickerFilterProps, setDatePickerFilterProps] = React.useState<TDatePickerFilterProps[]>([]);
	const [sortState, setSortState] = React.useState<{ [key: string]: TSortingDirection }>({});
	const tableFilterStore = useTableFilterContext();

	const preSelectedTime = React.useRef<{ startCreationTime: Date | undefined; endCreationTime: Date | undefined }>({
		startCreationTime: undefined,
		endCreationTime: undefined,
	});

	const handleSortChange = (key: string, direction: TSortingDirection | undefined) => {
		setSortState((prevState) => {
			const newState = { ...prevState };
			if (direction) {
				newState[key] = direction;
			} else {
				delete newState[key];
			}
			return newState;
		});
	};

	useEffect(() => {
		const datePickerFilterProps: TDatePickerFilterProps[] = [
			{
				key: "startCreationTime",
				props: {
					isValidDateCallback: (date) => {
						const clickedDate = dayjs(date);
						const today = dayjs();
						if (today.isBefore(clickedDate)) {
							showToast({ type: "danger", description: "Нельзя выбрать дату в будущем" });
							return false;
						}

						if (preSelectedTime.current.endCreationTime && dayjs(preSelectedTime.current.endCreationTime).isBefore(clickedDate)) {
							const endTime = dayjs(preSelectedTime.current.endCreationTime).format("DD.MM.YYYY");
							showToast({ type: "danger", description: `Нельзя указать дату после ${endTime}` });
							return false;
						}

						preSelectedTime.current.startCreationTime = date;
						return true;
					},
				},
			},
			{
				key: "endCreationTime",
				props: {
					isValidDateCallback: (date) => {
						const clickedDate = dayjs(date);
						const today = dayjs();

						if (today.isBefore(dayjs(date))) {
							showToast({ type: "danger", description: "Нельзя выбрать период в будущем" });
							return false;
						}

						if (preSelectedTime.current.startCreationTime && dayjs(preSelectedTime.current.startCreationTime).isAfter(clickedDate)) {
							const startTime = dayjs(preSelectedTime.current.startCreationTime).format("DD.MM.YYYY");
							showToast({ type: "danger", description: `Нельзя указать дату до ${startTime}` });
							return false;
						}

						preSelectedTime.current.endCreationTime = date;
						return true;
					},
				},
			},
		];
		setDatePickerFilterProps(datePickerFilterProps);
	}, []);

	const payments = useQuery({
		queryFn: () => {
			const sort = Object.entries(sortState).map(([key, direction]) => ({ key, direction }));

			const isEqualsDates = TableStore?.startCreationTime?.toISOString() === TableStore?.endCreationTime?.toISOString();

			return getPaymentsPageApi({
				params: {
					page: paginationData.clientsCurrentPage - 1,
					size: paginationData.clientsSize,
					sort,
					complexIds: tableFilterStore.selectedComplexIds,
					buildingIds: tableFilterStore.selectedBuildingsIds,
					entranceIds: tableFilterStore.selectedEntrancesIds,
					floorIds: tableFilterStore.selectedFloorsIds,
					buildingObjectIds: tableFilterStore.selectedBuildingObjectIds,
					clientFullNameSearch: TableStore.clientFullNameSearch,
					accountNumberSearch: TableStore.accountNumberSearch,
					amount: getAmount(TableStore.amount),
					commission: getAmount(TableStore.commission),
					amountWithoutCommission: getAmount(TableStore.amountWithoutCommission),
					startCreationTime: TableStore?.startCreationTime ? dayjs(TableStore?.startCreationTime).toISOString() : undefined,
					endCreationTime: TableStore?.endCreationTime
						? dayjs(TableStore?.endCreationTime)
								.add(1, "day")
								.toISOString()
						: undefined,
				},
			});
		},
		queryKey: [
			QueriesKeys.payments,
			paginationData.clientsSize,
			paginationData.clientsCurrentPage,
			tableFilterStore.selectedComplexIds,
			tableFilterStore.selectedBuildingsIds,
			tableFilterStore.selectedEntrancesIds,
			tableFilterStore.selectedFloorsIds,
			tableFilterStore.selectedBuildingObjectIds,
			TableStore.clientFullNameSearch,
			TableStore.accountNumberSearch,
			TableStore.amount,
			TableStore.commission,
			TableStore.amountWithoutCommission,
			TableStore?.startCreationTime,
			TableStore?.endCreationTime,
			sortState,
		],
	});

	useEffect(() => {
		if (payments.data) {
			paginationData.setClientsTotal(payments.data.totalPages);
		}
	}, [payments.data?.totalPages]);

	const columns: TTableColumn[] = [
		{
			dataIndex: "clientFullName",
			title: "ФИО плательщика",
			key: "clientFullName",
			onSearch: TableStore.setClientFullNameSearch,
			onSortedBy: (direction) => handleSortChange("clientFullName", direction),
			style: {
				minWidth: "260px",
			},
		},
		{
			dataIndex: "accountNumber",
			title: "Лицевой счет",
			key: "accountNumber",
			onSearch: (search) => {
				TableStore.setAccountNumberSearch(search);
				paginationData.setClientsCurrentPage(0);
				paginationData.setClientsSize(payments.data.size);
			},
			onSortedBy: (direction) => handleSortChange("accountNumber", direction),
			onSearchByFormatterString: (v) => v.replace(/[^\d]/g, ""),
			columnCellStyles: {
				minWidth: "100px",
			},
		},
		{
			dataIndex: "buildingObjectAddresses",
			title: "Адрес недвижимости",
			key: "buildingObjectAddresses",
			columnCellStyles: {
				minWidth: "300px",
			},
		},
		{
			dataIndex: "creationTime",
			title: "Дата и время начисления",
			key: "creationTime",
			filters: [
				{ text: "Current year", key: "startCreationTime", as: "date", label: "от" } as const,
				{ text: "Next year", key: "endCreationTime", as: "date", label: "до" } as const,
			],
			datePickerFiltersProps: [
				{
					key: "startCreationTime",
					props: {
						isValidDateCallback: (date) => {
							const clickedDate = dayjs(date);
							const today = dayjs().endOf("day");
							if (today.isBefore(clickedDate)) {
								showToast({ type: "danger", description: "Нельзя выбрать дату в будущем" });
								return false;
							}

							if (preSelectedTime.current.endCreationTime && dayjs(preSelectedTime.current.endCreationTime).isBefore(clickedDate)) {
								const endTime = dayjs(preSelectedTime.current.endCreationTime).format("DD.MM.YYYY");
								showToast({ type: "danger", description: `Нельзя указать дату после ${endTime}` });
								return false;
							}

							preSelectedTime.current.startCreationTime = date;
							return true;
						},
					},
				},
				{
					key: "endCreationTime",
					props: {
						isValidDateCallback: (date) => {
							const clickedDate = dayjs(date);
							const today = dayjs();

							if (today.isBefore(dayjs(date))) {
								showToast({ type: "danger", description: "Нельзя выбрать период в будущем" });
								return false;
							}

							if (preSelectedTime.current.startCreationTime && dayjs(preSelectedTime.current.startCreationTime).isAfter(clickedDate)) {
								const startTime = dayjs(preSelectedTime.current.startCreationTime).format("DD.MM.YYYY");
								showToast({ type: "danger", description: `Нельзя указать дату до ${startTime}` });
								return false;
							}

							preSelectedTime.current.endCreationTime = date;
							return true;
						},
					},
				},
			],
			onChangeFilter: (data) => {
				const newDatePickerProps = [...datePickerFilterProps];
				const newTime = { startCreationTime: TableStore.startCreationTime, endCreationTime: TableStore.endCreationTime };
				data.forEach((item) => {
					const date = item.resultData?.dates?.date;
					const datePickerItemIndex = newDatePickerProps.findIndex((filter) => {
						if (item.key === "startCreationTime") {
							return filter.key === "endCreationTime";
						}
						return filter.key === "startCreationTime";
					});

					newTime[item.key] = date ? new Date(date) : undefined;
					if (!item.resultData.dates) {
						newDatePickerProps[datePickerItemIndex].props = {
							disabledDates: undefined,
						};
					}
				});
				TableStore.setStartCreationTime(newTime.startCreationTime);
				TableStore.setEndCreationTime(newTime.endCreationTime);
				paginationData.setClientsCurrentPage(0);
				paginationData.setClientsSize(payments.data.size);

				const [from, to] = data;
				preSelectedTime.current.startCreationTime = from.resultData?.dates?.date;
				preSelectedTime.current.endCreationTime = to.resultData?.dates?.date;
			},
			onSortedBy: (direction) => handleSortChange("creationTime", direction),
			columnCellStyles: {
				maxWidth: "200px",
				minWidth: "200px",
			},
		},
		{
			dataIndex: "amount",
			title: "Сумма начисления",
			key: "amount",
			onSearch: (value) => {
				TableStore.setAmount(value);
				paginationData.setClientsCurrentPage(0);
				paginationData.setClientsSize(payments.data.size);
			},
			onSortedBy: (direction) => handleSortChange("amount", direction),
			onSearchByFormatterString: (v) => v.replace(/[^0-9.,]/g, ""),
			columnCellStyles: {
				maxWidth: "180px",
				minWidth: "180px",
			},
		},
		{
			dataIndex: "commission",
			title: "Сумма комиссии",
			key: "commission",
			columnCellStyles: {
				maxWidth: "180px",
				minWidth: "180px",
			},
			onSortedBy: (direction) => handleSortChange("commission", direction),
			onSearchByFormatterString: (v) => v.replace(/[^0-9.,]/g, ""),
			onSearch: (value) => {
				TableStore.setCommission(value);
				paginationData.setClientsCurrentPage(0);
				paginationData.setClientsSize(payments.data.size);
			},
		},
		{
			dataIndex: "amountWithoutCommission",
			title: "Сумма начисления без комиссии",
			key: "amountWithoutCommission",
			columnCellStyles: {
				maxWidth: "180px",
				minWidth: "180px",
			},
			onSortedBy: (direction) => handleSortChange("amountWithoutCommission", direction),
			onSearchByFormatterString: (v) => v.replace(/[^0-9.,]/g, ""),
			onSearch: (value) => {
				TableStore.setAmountWithoutCommission(value);
				paginationData.setClientsCurrentPage(0);
				paginationData.setClientsSize(payments.data.size);
			},
		},
	];

	const data =
		payments.data?.content?.map((payment) => ({
			clientFullName: payment.clientFullName,
			accountNumber: payment.account.number,
			buildingObjectAddresses: payment.account.buildingObjectAddresses.map((address) => address.addressValue).join(", "),
			creationTime: dayjs(payment.creationTime).format("DD.MM.YYYY HH:mm"),
			amount: getFormtattedSum(payment.amount.toString()),
			commission: getFormtattedSum(payment.commission.toString()),
			amountWithoutCommission: getFormtattedSum(payment.amountWithoutCommission.toString()),
		})) ?? [];

	return {
		data,
		columns,
		isFetching: payments.isFetching,
		paginationData,
	};
};

export default useTableData;
