import { FC, useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Avatar, Badge, Button, Text, Tooltip, colors, Chip } from "tap2visit-ui-kit";

import { getBuildingObjectIdsByUserIdApi } from "api/api.realEstate";
import { downloadFileApi } from "api/api.storage";
import CitizensDrawerStore from "components/Citizens/store/Citizens.drawer.store";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import EmployeeDrawerStore from "pages/employee/store/Employee.drawer.store";
import { ReactComponent as SmartPhone } from "public/SmartPhone.svg";
import getUserRoleByKey from "utils/getUserRoleByKey";

import TextHeader from "../TextHeader";

import getCitizenStatus from "./utils/getCitizenStatus";
import getRoleTranslate from "utils/getRoleTranslate";

interface IUserAvatarAndInitials {
	fullName?: string;
	role: string;
}

const UserAvatarAndInitials: FC<IUserAvatarAndInitials> = (props) => {
	const location = useLocation().pathname;
	const [img, setImg] = useState("");

	const selectedUser = CitizensDrawerStore.selectedUser;
	const citizenUserStatus = getCitizenStatus({ lastActivity: selectedUser.lastActivity, registration: selectedUser.registration });

	const isCitizenPage = location === "/citizens";

	const citizenBuildingObjectsIds = useQuery({
		queryFn: () => getBuildingObjectIdsByUserIdApi({ path: { clientId: CitizensDrawerStore.selectedUser.id } }),
		enabled: !!CitizensDrawerStore.selectedUser.id,
		queryKey: [QueriesKeys.citizenBuildingObjectsIds, CitizensDrawerStore.selectedUser.id],
	});

	const citizenRoles = Array.from(
		citizenBuildingObjectsIds.data
			?.flatMap((item) => item.roles)
			// ? withour duplicates
			.filter((role, idx, arr) => arr.slice(0, idx).every((currRole) => role.name !== currRole.name)) ?? [],
	);
	const citizenRolesTextList = citizenRoles.map((role) => getRoleTranslate(role.name));

	const [lastName, firstName] = props.fullName?.split(" ") ?? ["", ""];

	useQuery({
		queryFn: () => {
			if (CitizensDrawerStore.selectedUser.avatarId) {
				return downloadFileApi(CitizensDrawerStore.selectedUser.avatarId);
			}
			setImg(undefined);
		},
		enabled: !!CitizensDrawerStore.selectedUser,
		queryKey: [QueriesKeys.downloadFileCitizen, CitizensDrawerStore.selectedUser?.avatarId],
		onSuccess: (data) => {
			if (data) {
				const blob = new Blob([data]);
				const imgLink = URL.createObjectURL(blob);
				setImg(imgLink);
				return;
			}
			setImg(undefined);
		},
	});
	useEffect(() => {
		queryClient.invalidateQueries([QueriesKeys.downloadFileCitizen]);
	}, [CitizensDrawerStore]);
	return (
		<>
			<Tooltip style={{ zIndex: 1000 }} id="user_mobile_status">
				{citizenUserStatus.hint}
			</Tooltip>
			<UserInfoContainer>
				<Avatar
					size={isCitizenPage ? "80" : "96"}
					text={`${lastName?.[0]} ${firstName?.[0]}`}
					img={img}
					badgeIcon={
						isCitizenPage &&
						(() => (
							<SmartPhoneIconContainer data-tooltip-id="user_mobile_status" data-tooltip-place="bottom" data-tooltip-variant="light">
								<SmartPhone fill={citizenUserStatus.color} />
							</SmartPhoneIconContainer>
						))
					}
					badgeIconBackground="white"
				/>
				<TextInfoContainer>
					<TextWrapper>
						<CustomTextHeader h="h3">{props.fullName}</CustomTextHeader>
					</TextWrapper>

					{isCitizenPage && (
						<ChipContainer>
							{citizenRolesTextList.map((role) => {
								return <Chip key={role} title={role} size={"m"} />;
							})}
						</ChipContainer>
					)}
					{!isCitizenPage && (
						<div>
							<Chip size="m" title={getUserRoleByKey(EmployeeDrawerStore.selectedUser.role)} />
						</div>
					)}
				</TextInfoContainer>
			</UserInfoContainer>
		</>
	);
};

export default observer(UserAvatarAndInitials);

const UserInfoContainer = styled.div`
	display: flex;
	gap: 16px;
	align-items: flex-start;
`;

const ButtonWrapper = styled(Button)`
	p {
		font-weight: 600;
	}
`;

const TextInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 80%;
	overflow: hidden;
`;

const RolesInfoContainer = styled.div`
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
`;

const AvatarContainer = styled.div`
	border: 4px solid ${colors.strokeFloatBorder};
	border-radius: 50%;

	box-shadow: 4px 4px 29px 0px rgba(16, 24, 40, 0.08);
`;

const TextWrapper = styled.div``;

const CustomTextHeader = styled(Text)`
	width: 100%;
	color: ${colors.textNeutralPrimary};
	white-space: break-spaces;
	word-break: break-all;
`;

const SmartPhoneIconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

const ChipContainer = styled.div`
	display: flex;
	gap: 8px;
`;
