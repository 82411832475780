import { useState } from "react";

import { useMutation } from "react-query";
import styled from "styled-components";

import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import { ReactComponent as TrashIcon } from "public/Trash.svg";

import { createContactApi, deleteClientContactApi } from "../../../api/api.clients";
import CitizensDrawerStore from "../../../components/Citizens/store/Citizens.drawer.store";

import CitizenContact from "./CitizenContact/CitizenContact";

const CitizenPhone = () => {
	const citizenPhoneMap = CitizensDrawerStore.selectedUser.contacts?.filter((v) => !v.isDefault && v.type === "PHONE");
	const [isOpen, setIsOpen] = useState(false);

	const createClientContact = useMutation(createContactApi);
	const deleteClientContact = useMutation(deleteClientContactApi);

	const onChange = async (phone: string, description: string) => {
		const dataInputs = CitizensDrawerStore.selectedUser;
		await createClientContact
			.mutateAsync({
				body: {
					clientId: dataInputs.id,
					description: description,
					isDefault: false,
					type: "PHONE",
					value: phone,
				},
			})

			.then(() => {
				setIsOpen(false);
				dataInputs.contacts.push({ description, isDefault: false, type: "PHONE", value: phone });
			});

		queryClient.invalidateQueries([QueriesKeys.clients]);
	};

	const deletePhone = async (id: string) => {
		await deleteClientContact.mutateAsync({ path: { id } });

		const removedContactIdx = CitizensDrawerStore.selectedUser.contacts.findIndex((contact) => contact.id === id);
		if (removedContactIdx !== -1) {
			CitizensDrawerStore.removeContact(removedContactIdx);
		}

		queryClient.invalidateQueries([QueriesKeys.clients]);
	};

	return (
		<CitizenContact
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			title={"Номера телефонов"}
			contactsCollection={citizenPhoneMap}
			onSave={onChange}
			onDeleteContact={deletePhone}
			modalTitle={"Добавить номер телефона"}
			modalTitlePlaceholder="+7 000 000-00-00"
			modalTitleLabel="Телефон"
			modalDescriptionPlaceholder="Например: телефон собственника"
			modalTitleMask="+7 (999) 999 99 99"
			buttonText={"Добавить номер телефона"}
			type="PHONE"
		/>
	);
};

export default CitizenPhone;

const TrashIconContainer = styled(TrashIcon)`
	cursor: pointer;
`;
