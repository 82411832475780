import { IBuildingBase, IBuildingObjectPageParams, IBuildingObjectsPage } from "interfaces/IBuilding";
import {
	IChatListItem,
	IChatListResponse,
	IFileListResponse,
	IGetChatByIdArgs,
	IGetFileListChatArgs,
	IGetListArgs,
} from "interfaces/IChat";
import { IClientsGateway, ICreateClient } from "interfaces/IClient";
import { ICountersPage, ICountersPageMvpParams, ICountersPageParams, IMvpCountersPage } from "interfaces/ICounter";
import { IPaymentPageParams, IPaymentPage, IPaymentPageMvpParams, IMvpPaymentPage } from "interfaces/IPayment";
import { IClient } from "interfaces/IRole";
import { TSortingDirection } from "interfaces/ISorting";
import { IVotePage, IVotePageParams } from "interfaces/IVote";
import { joinArrParams } from "utils/joinParams";

import {
	IAnnouncement,
	IAnnouncementCount,
	IAnnouncementHasBeenEdited,
	IAnnouncementsChangeGateway,
	IAnnouncementsGateway,
	IAnnouncementsGetCount,
	IAnnouncementsList,
	IAnnouncementsListGatewayBody,
	IAnnouncementsPostGateway,
	IAnnouncementsTemplatesArgs,
	IAnnouncementsTemplatesChangeGateway,
	IAnnouncementsTemplatesItem,
	IAnnouncementsTemplatesPostGateway,
	IAnnouncementsTemplatesResGateway,
} from "../interfaces/IAnnouncements";
import {
	IBuildingBaseGetParams,
	IBuildings,
	IBuildingsGetParams,
	IComplexes,
	IComplexesGetParams,
	IEntrances,
	IEntrancesGetParams,
	IFloors,
	IFloorsGetParams,
} from "../interfaces/IComplexes";
import { IRequest, IRequestGateway } from "../interfaces/IRequest";
import * as requestAnnouncements from "../mocks/mocks.announcements";
import { isMockEnabled } from "../mocks/mocks.constants";
import * as requestMock from "../mocks/mocks.request";

import apiClient from "./api.client";

export type TGetClientApiParams = {
	sort?: { key: string; direction: string }[];
	emailSearch?: string;
	phoneNumberSearch?: string;
	fullNameSearch?: string;
	accountNumberSearch?: string;
	complexIds?: string[];
	buildingIds?: string[];
	entranceIds?: string[];
	floorIds?: string[];
	buildingObjectIds?: string[];
	roleIds?: string[];
	page?: number;
	size?: number;
};

export type TGetClientApi = (args: { params: TGetClientApiParams }) => Promise<IClientsGateway>;
export type TGetRequestApi = (args: IRequestGateway) => Promise<IRequest>;
export type TGetAnnouncementsByIdApi = (args: IAnnouncementsGateway) => Promise<IAnnouncement>;
export type TGetAnnouncementsListApi = (args: IAnnouncementsListGatewayBody) => Promise<IAnnouncementsList>;
export type TGetAnnouncementsTemplatesList = (args: IAnnouncementsTemplatesArgs) => Promise<IAnnouncementsTemplatesItem[]>;
export type TPostAnnouncementsApi = (args: IAnnouncementsPostGateway) => Promise<void>;
export type TPostAnnouncementsTemplatesApi = (args: IAnnouncementsTemplatesPostGateway) => Promise<IAnnouncementsTemplatesResGateway>;
export type TChangeAnnouncementsTemplatesApi = (args: IAnnouncementsTemplatesChangeGateway) => Promise<IAnnouncementsTemplatesResGateway>;
export type TChangeAnnouncementsApi = (args: IAnnouncementsChangeGateway & IAnnouncementHasBeenEdited) => Promise<IAnnouncement>;
export type TGetEntrancesApi = (args: { params: IEntrancesGetParams }) => Promise<IEntrances[]>;
export type TGetBuildingsApi = (args: { params: IBuildingsGetParams }) => Promise<IBuildings[]>;
export type TGetBuildingsBaseApi = (args?: { params: IBuildingBaseGetParams }) => Promise<IBuildingBase[]>;
export type TGetComplexesApi = (args: { params: IComplexesGetParams }) => Promise<IComplexes[]>;
export type TGetFloorsApi = (args: { params: IFloorsGetParams }) => Promise<IFloors[]>;
export type TGetListChat = (args: { params: IGetListArgs }) => Promise<IChatListResponse>;
export type TGetChatById = (args: { params: IGetChatByIdArgs }) => Promise<IChatListItem>;
export type TGetFilesListChat = (args: { params: IGetFileListChatArgs }) => Promise<IFileListResponse>;

export const getClientApi: TGetClientApi = async (args) => {
	try {
		const { sort, floorIds, buildingIds, complexIds, entranceIds, roleIds, buildingObjectIds, ...otherParams } = args.params;

		let path = "api-gateway/v1/clients";
		let sortParams = "?";

		const params: Record<string, string | number> = {
			...otherParams,
		};

		if (floorIds?.length) {
			params.floorIds = floorIds.join(",");
		}
		if (buildingIds?.length) {
			params.buildingIds = buildingIds.join(",");
		}
		if (complexIds?.length) {
			params.complexIds = complexIds.join(",");
		}
		if (entranceIds?.length) {
			params.entranceIds = entranceIds.join(",");
		}

		if (roleIds?.length) {
			params.roleIds = roleIds.join(",");
		}

		if (buildingObjectIds?.length) {
			params.buildingObjectIds = buildingObjectIds.join(",");
		}

		if (sort?.length) {
			sort.forEach((sort, index) => {
				if (index !== 0) {
					sortParams += "&";
				}
				sortParams += `sort=${sort.key},${sort.direction}`;
			});
			path += sortParams;
		}

		const res = await apiClient.get(path, {
			params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TCreateClientApi = (args: { body: ICreateClient }) => Promise<IClient>;

export const createClientApi: TCreateClientApi = async (args) => {
	try {
		const res = await apiClient.post("api-gateway/v1/clients", args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getRequestApi: TGetRequestApi = async (args) => {
	try {
		if (isMockEnabled) {
			return requestMock.allUsersRequest;
		}

		const { sort, statuses, ...otherArgs } = args;

		let sortParams = "?";

		sortParams += `sort=fullName,${sort.fullName}`;
		sortParams += `&sort=accountValue,${sort.accountValue}`;

		if (statuses) {
			sortParams += `&statuses=${statuses.join(",")}`;
		}

		const res = await apiClient.get(`api-gateway/v1/requests${sortParams}`, {
			params: otherArgs,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getAnnouncementsByIdApi: TGetAnnouncementsByIdApi = async (args) => {
	try {
		const res = await apiClient.get(`api-gateway/v1/announcements/${args.id}`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getAnnouncementsListApi: TGetAnnouncementsListApi = async (args) => {
	try {
		if (isMockEnabled) {
			return requestAnnouncements.allAnnouncements;
		}

		const { sort, ...otherArgs } = args;

		const sortStrings = [];

		if (sort.type !== undefined) {
			sortStrings.push(`sort=type,${sort.type}`);
		}
		if (sort.createDateTime !== undefined) {
			sortStrings.push(`sort=createDateTime,${sort.createDateTime}`);
		}
		if (sort.startDateTime !== undefined) {
			sortStrings.push(`sort=startDateTime,${sort.startDateTime}`);
		}
		if (sort.endDateTime !== undefined) {
			sortStrings.push(`sort=endDateTime,${sort.endDateTime}`);
		}

		let sortString = "?";

		if (sortStrings.length > 0) {
			sortString += sortStrings.join("&");
		}

		const res = await apiClient.get(`api-gateway/v1/announcements${sortString}`, {
			params: otherArgs,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const postAnnouncementsApi: TPostAnnouncementsApi = async (args) => {
	try {
		const res = await apiClient.post("api-gateway/v1/announcements", {
			...args,
		});
		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const postAnnouncementsTemplatesApi: TPostAnnouncementsTemplatesApi = async (args) => {
	try {
		const res = await apiClient.post("announcements/v1/announcements/templates", {
			...args,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
export const getAnnouncementsTemplatesListApi: TGetAnnouncementsTemplatesList = async (args) => {
	try {
		if (isMockEnabled) {
			return requestAnnouncements.allAnnouncementsTemplates;
		}

		const res = await apiClient.get("announcements/v1/announcements/templates", {
			params: args,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteAnnouncementsTemplateApi = async (id: string) => {
	try {
		const res = await apiClient.delete(`announcements/v1/announcements/templates/${id}`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const changeAnnouncementsTemplatesApi: TChangeAnnouncementsTemplatesApi = async (args) => {
	try {
		const res = await apiClient.put(`announcements/v1/announcements/templates/${args.id}`, {
			...args,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const putAnnouncementsApi: TChangeAnnouncementsApi = async (args) => {
	try {
		const res = await apiClient.put(`api-gateway/v1/announcements/${args.id}`, {
			...args,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetAnnouncementsCountApi = (args: { params: IAnnouncementsGetCount }) => Promise<IAnnouncementCount>;

export const getAnnouncementsCountApi: TGetAnnouncementsCountApi = async (args) => {
	try {
		const res = await apiClient.get("api-gateway/v1/announcements/count", {
			params: {
				types: joinArrParams(args.params.types),
				statuses: joinArrParams(args.params.statuses),
			},
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getComplexesApi: TGetComplexesApi = async (args) => {
	try {
		const res = await apiClient.get("api-gateway/v1/complexes", args);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getBuildingsApi: TGetBuildingsApi = async (args) => {
	try {
		const res = await apiClient.get("api-gateway/v1/buildings", args);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getBuildingsBaseApi: TGetBuildingsBaseApi = async (args) => {
	try {
		const res = await apiClient.get("api-gateway/v1/buildings/base", {
			params: {
				complexIds: joinArrParams(args?.params?.complexIds),
				buildingIds: joinArrParams(args?.params?.buildingIds),
			},
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getEntrancesApi: TGetEntrancesApi = async (args) => {
	try {
		const res = await apiClient.get("api-gateway/v1/entrances", args);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
export const getFloorsApi: TGetFloorsApi = async (args) => {
	try {
		const res = await apiClient.get("api-gateway/v1/floors", args);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetBuildingObjectsPageApi = (args: { params: IBuildingObjectPageParams }) => Promise<IBuildingObjectsPage>;

export const getBuildingObjectsPageApi: TGetBuildingObjectsPageApi = async (args) => {
	try {
		const { buildingIds, complexIds, entranceIds, floorIds, types, ...otherParams } = args.params;

		const params: Record<string, any> = {
			...otherParams,
			types: types.join(","),
		};

		if (complexIds?.length) {
			params.complexIds = complexIds.join(",");
		}

		if (buildingIds?.length) {
			params.buildingIds = buildingIds.join(",");
		}

		if (entranceIds?.length) {
			params.entranceIds = entranceIds.join(",");
		}

		if (floorIds?.length) {
			params.floorIds = floorIds.join(",");
		}

		const res = await apiClient.get("api-gateway/v1/building-objects/page", {
			params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetPollsPageApi = (args: { params: IVotePageParams }) => Promise<IVotePage>;

export const getPollsPageApi: TGetPollsPageApi = async (args) => {
	try {
		const {
			buildingIds,
			complexIds,
			entranceIds,
			floorIds,
			buildingObjectIds,
			nameSearch,
			types,
			statuses,
			sort,
			fullName,
			...otherParams
		} = args.params;

		let path = "api-gateway/v1/votes";
		let sortParams = "?";

		const params: Record<string, any> = {
			...otherParams,
		};

		if (complexIds?.length) {
			params.complexIds = complexIds.join(",");
		}

		if (buildingIds?.length) {
			params.buildingIds = buildingIds.join(",");
		}

		if (entranceIds?.length) {
			params.entranceIds = entranceIds.join(",");
		}

		if (floorIds?.length) {
			params.floorIds = floorIds.join(",");
		}

		if (buildingObjectIds?.length) {
			params.buildingObjectIds = buildingObjectIds.join(",");
		}

		if (nameSearch) {
			params.nameSearch = nameSearch;
		}

		if (fullName) {
			params.fullName = fullName;
		}

		if (types) {
			params.types = types.join(",");
		}

		if (statuses?.length) {
			params.statuses = statuses.join(",");
		}

		if (sort?.length) {
			sort.forEach((sort, index) => {
				if (index !== 0) {
					sortParams += "&";
				}
				sortParams += `sort=${sort.key},${sort.direction}`;
			});
			path += sortParams;
		}

		const res = await apiClient.get(path, {
			params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetPaymentsPageApi = (args: { params: IPaymentPageParams }) => Promise<IPaymentPage>;

export const getPaymentsPageApi: TGetPaymentsPageApi = async (args) => {
	try {
		const {
			buildingIds,
			complexIds,
			entranceIds,
			floorIds,
			buildingObjectIds,
			accountNumberSearch,
			clientFullNameSearch,
			endCreationTime,
			startCreationTime,
			amount,
			commission,
			amountWithoutCommission,
			sort,

			...otherParams
		} = args.params;

		let path = "api-gateway/v1/payments";
		let sortParams = "?";

		const params: Record<string, any> = {
			...otherParams,
		};

		if (complexIds?.length) {
			params.complexIds = complexIds.join(",");
		}

		if (buildingIds?.length) {
			params.buildingIds = buildingIds.join(",");
		}

		if (entranceIds?.length) {
			params.entranceIds = entranceIds.join(",");
		}

		if (floorIds?.length) {
			params.floorIds = floorIds.join(",");
		}
		if (buildingObjectIds?.length) {
			params.buildingObjectIds = buildingObjectIds.join(",");
		}

		if (accountNumberSearch) {
			params.accountNumberSearch = accountNumberSearch;
		}

		if (clientFullNameSearch) {
			params.clientFullNameSearch = clientFullNameSearch;
		}

		if (endCreationTime) {
			params.endCreationTime = endCreationTime;
		}

		if (startCreationTime) {
			params.startCreationTime = startCreationTime;
		}

		if (amount) {
			params.amount = amount;
		}
		if (commission) {
			params.commission = commission;
		}
		if (amountWithoutCommission) {
			params.amountWithoutCommission = amountWithoutCommission;
		}

		if (sort?.length) {
			sort.forEach((sort, index) => {
				if (index !== 0) {
					sortParams += "&";
				}
				sortParams += `sort=${sort.key},${sort.direction}`;
			});
			path += sortParams;
		}

		const res = await apiClient.get(path, {
			params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetPaymentsPageMvpApi = (args: { params: IPaymentPageMvpParams }) => Promise<IMvpPaymentPage>;

export const getPaymentsPageMvpApi: TGetPaymentsPageMvpApi = async (args) => {
	try {
		const { accountNumberSearch, endCreationTime, startCreationTime, amount, sort, ...otherParams } = args.params;

		let path = "api-gateway/v1/payments-mvp";
		let sortParams = "?";

		const params: Record<string, any> = {
			...otherParams,
		};

		if (accountNumberSearch) {
			params.accountNumberSearch = accountNumberSearch;
		}

		if (endCreationTime) {
			params.endCreationTime = endCreationTime;
		}

		if (startCreationTime) {
			params.startCreationTime = startCreationTime;
		}

		if (amount) {
			params.amount = amount;
		}

		if (sort?.length) {
			sort.forEach((sort, index) => {
				if (index !== 0) {
					sortParams += "&";
				}
				sortParams += `sort=${sort.key},${sort.direction}`;
			});
			path += sortParams;
		}

		const res = await apiClient.get(path, {
			params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetCountersPageMvpApi = (args: { params: ICountersPageMvpParams }) => Promise<IMvpCountersPage>;

export const getCountersPageMvpApi: TGetCountersPageMvpApi = async (args) => {
	try {
		const {
			accountNumberSearch,
			meteringDeviceCodeSearch,
			meteringDeviceSerialNumberSearch,
			serviceType,
			startCreationTime,
			endCreationTime,
			value,
			previousValue,
			consumption,
			sort,
			...otherParams
		} = args.params;

		let path = "api-gateway/v1/metering-device-mvp/values";
		let sortParams = "?";

		const params: Record<string, any> = {
			...otherParams,
		};

		if (accountNumberSearch) {
			params.accountNumberSearch = accountNumberSearch;
		}

		if (endCreationTime) {
			params.endCreationTime = endCreationTime;
		}

		if (startCreationTime) {
			params.startCreationTime = startCreationTime;
		}

		if (meteringDeviceCodeSearch) {
			params.meteringDeviceCodeSearch = meteringDeviceCodeSearch;
		}

		if (meteringDeviceSerialNumberSearch) {
			params.meteringDeviceSerialNumberSearch = meteringDeviceSerialNumberSearch;
		}
		if (serviceType) {
			params.serviceType = serviceType;
		}
		if (value) {
			params.value = value;
		}
		if (previousValue) {
			params.previousValue = previousValue;
		}
		if (consumption) {
			params.consumption = consumption;
		}
		if (sort?.length) {
			sort.forEach((sort, index) => {
				if (index !== 0) {
					sortParams += "&";
				}
				sortParams += `sort=${sort.key},${sort.direction}`;
			});
			path += sortParams;
		}

		const res = await apiClient.get(path, {
			params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetCountersPageApi = (args: { params: ICountersPageParams }) => Promise<ICountersPage>;

export const getCountersPageApi: TGetCountersPageApi = async (args) => {
	try {
		const {
			accountNumberSearch,
			meteringDeviceCodeSearch,
			meteringDeviceSerialNumberSearch,
			serviceType,
			startCreationTime,
			endCreationTime,
			value,
			previousValue,
			consumption,
			sort,
			floorIds,
			buildingIds,
			complexIds,
			entranceIds,
			buildingObjectIds,
			...otherParams
		} = args.params;

		let path = "api-gateway/v1/metering-device/values";
		let sortParams = "?";

		const params: Record<string, any> = {
			...otherParams,
		};

		if (floorIds?.length) {
			params.floorIds = floorIds.join(",");
		}
		if (buildingIds?.length) {
			params.buildingIds = buildingIds.join(",");
		}
		if (complexIds?.length) {
			params.complexIds = complexIds.join(",");
		}
		if (entranceIds?.length) {
			params.entranceIds = entranceIds.join(",");
		}

		if (buildingObjectIds?.length) {
			params.buildingObjectIds = buildingObjectIds.join(",");
		}
		if (accountNumberSearch) {
			params.accountNumberSearch = accountNumberSearch;
		}

		if (endCreationTime) {
			params.endCreationTime = endCreationTime;
		}

		if (startCreationTime) {
			params.startCreationTime = startCreationTime;
		}

		if (meteringDeviceCodeSearch) {
			params.meteringDeviceCodeSearch = meteringDeviceCodeSearch;
		}

		if (meteringDeviceSerialNumberSearch) {
			params.meteringDeviceSerialNumberSearch = meteringDeviceSerialNumberSearch;
		}
		if (serviceType) {
			params.serviceType = serviceType;
		}
		if (value) {
			params.value = value;
		}
		if (previousValue) {
			params.previousValue = previousValue;
		}
		if (consumption) {
			params.consumption = consumption;
		}
		if (sort?.length) {
			sort.forEach((sort, index) => {
				if (index !== 0) {
					sortParams += "&";
				}
				sortParams += `sort=${sort.key},${sort.direction}`;
			});
			path += sortParams;
		}

		const res = await apiClient.get(path, {
			params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TMateringDeviceApiApi = (args: { params: { startCreationTime: string; endCreationTime: string } }) => Promise<any>;

export const createMateringDeviceApi: TMateringDeviceApiApi = async (args) => {
	try {
		const res = await apiClient.post(
			"api-gateway/v1/reports/metering-device/values",
			{},
			{
				params: args.params,
				responseType: "blob",
				headers: {
					Accept: "application/octet-stream",
				},
			},
		);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TCreateReportApi = (args: { params: { startCreationTime: string; endCreationTime: string } }) => Promise<any>;

export const createReportApi: TCreateReportApi = async (args) => {
	try {
		const res = await apiClient.post(
			"api-gateway/v1/reports/payments",
			{},
			{
				params: args.params,
				responseType: "blob",
				headers: {
					Accept: "application/octet-stream",
				},
			},
		);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TCreateReportMvpApi = (args: { params: { startCreationTime: string; endCreationTime: string } }) => Promise<any>;

export const createReportMvpApi: TCreateReportMvpApi = async (args) => {
	try {
		const res = await apiClient.post(
			"api-gateway/v1/reports/payments-mvp",
			{},
			{
				params: args.params,
				responseType: "blob",
				headers: {
					Accept: "application/octet-stream",
				},
			},
		);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getListChat: TGetListChat = async (args) => {
	try {
		const res = await apiClient.get("api-gateway/v1/chats/page", {
			params: {
				query: args.params.query,
				complexId: args.params.complexId,
			},
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getChatById: TGetChatById = async (args) => {
	try {
		const res = await apiClient.get("api-gateway/v1/chats", {
			params: {
				clientId: args.params.clientId,
			},
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getListFilesChat: TGetFilesListChat = async (args) => {
	try {
		const res = await apiClient.get("api-gateway/v1/chat-files/page", {
			params: {
				chatId: args.params.chatId,
			},
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
