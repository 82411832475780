import React, { FC } from "react";

import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useMutation } from "react-query";
import { Button, Space, Text, colors, showToast } from "tap2visit-ui-kit";

import { createDocumentApi, updateDocumentApi } from "api/api.documents";
import { IDocumentCreate } from "interfaces/IDocuments";
import { ValidationStatuses } from "modules/validation/Validation.store";
import { ReactComponent as EditIcon } from "public/EditCitizen.svg";
import { cleanString } from "utils/format/cleanString";

import CitizenFiles from "../../../components/Citizens/components/CitizenFiles";
import UserEditInput, { UserEditDate } from "../../../components/Citizens/components/UserEdit.input";
import { editOrCreateCitizenInputMaxLength } from "../../../components/Citizens/constants/InputCitizen.constants";
import useNewFileState from "../../../components/Citizens/hooks/useNewFileState";
import useUploadCitizenFiles from "../../../components/Citizens/hooks/useUploadCitizenFiles";
import CitizenCreateStore, {
	citizenCreateStoreInputMask,
	citizenCreateStoreInputMaxLength,
} from "../../../components/Citizens/store/CitizenCreate.store";
import CitizensDrawerStore from "../../../components/Citizens/store/Citizens.drawer.store";
import {
	CitizenPersonalEdit,
	CitizenPersonalEditFooter,
	CitizenPersonalEditWrapper,
	CitizenRealtyDivider,
	CitizenRealtyWrapper,
	CitizenWrapper,
	PersonalInformationWrapper,
} from "../../../components/Citizens/style/Citizen.styled";
import useCitizenDocumentByType from "../hooks/useCitizenDocumentByType";
import useRefreshCitizenAfterUpdate from "../hooks/useRefreshCitizenAfterUpdate";
import CitizenPassportValidationStore, { TCitizenPassportValidationFields } from "../store/CitizenPassportValidation.store";

import { CitizenDrawerTextHeaderBlock } from "./CitizenTexts";

const getPassportValue = (value: string) => ((value?.length ?? 0) > 0 ? value : "Данные отсутствуют");

export type TCitizenPassportOnDataChange = (args: {
	series: string;
	number: string;
	departmentCode: string;
	dateOfBirth: dayjs.Dayjs;
	dateOfIssue: dayjs.Dayjs;
	issueBy: string;
	address: string;
	files: File[];
}) => void;

interface ICitizenPassport {
	onlyEditable?: boolean; // ? when we creating a new user
	onDataChange?: TCitizenPassportOnDataChange; // ? for a new user
}

const onChangeInputBase = (callback: () => void, key: keyof TCitizenPassportValidationFields) => {
	CitizenPassportValidationStore.validateField(key);
	callback();
};

const CitizenPassport: FC<ICitizenPassport> = (props) => {
	const [series, setSeries] = React.useState("");
	const [number, setNumber] = React.useState("");
	const [departmentCode, setDepartmentCode] = React.useState("");
	const [dateOfBirth, setDateOfBirth] = React.useState<dayjs.Dayjs>();
	const [dateOfIssue, setDateOfIssue] = React.useState<dayjs.Dayjs>();
	const [issueBy, setIssueBy] = React.useState("");
	const [address, setAddress] = React.useState("");

	const { needToRemoveFileIds, newFiles, setNeedToRemoveFileIds, setNewFiles } = useNewFileState();

	const [isEdit, setIsEdit] = React.useState(!!props.onlyEditable);
	const refreshUser = useRefreshCitizenAfterUpdate({
		setIsEdit,
		setNewFiles,
	});
	const citizenDocument = useCitizenDocumentByType({ type: ["PASSPORT"] });

	const createDocument = useMutation(createDocumentApi);
	const updateDocument = useMutation(updateDocumentApi);

	const uploadFiles = useUploadCitizenFiles({
		needToRemoveFileIds: needToRemoveFileIds,
		newFiles: newFiles,
		citizenDocument: citizenDocument,
	});

	React.useEffect(() => {
		if (props.onDataChange) {
			props.onDataChange({
				address,
				dateOfBirth,
				dateOfIssue,
				departmentCode,
				issueBy,
				number,
				series,
				files: newFiles,
			});
		}
	}, [series, number, departmentCode, dateOfIssue, issueBy, address, newFiles]);

	React.useEffect(() => {
		if (citizenDocument) {
			setSeries(citizenDocument.series);
			setNumber(citizenDocument.number);
			setDepartmentCode(citizenDocument.departmentCode);
			setDateOfBirth(dayjs(citizenDocument.dateOfBirth));
			setDateOfIssue(dayjs(citizenDocument.dateOfIssue));
			setIssueBy(citizenDocument.issueBy);
			setAddress(citizenDocument.address);
		}
	}, [citizenDocument]);

	const seriesAndNumberOfPassport =
		citizenDocument?.series && citizenDocument?.number
			? `${citizenDocument.series.slice(0, 2) ?? ""} ${citizenDocument.series.slice(2, 4) ?? ""} ${citizenDocument?.number ?? ""}`
			: "";

	const updateDocuments = async () => {
		const newFileIds = await uploadFiles();

		const validationPassportFields = {
			series: cleanString(series),
			number,
			departmentCode: cleanString(departmentCode),
			dateOfIssue: dateOfIssue ? dayjs(dateOfIssue).format("YYYY-MM-DD") : undefined,
			dateOfBirth: dateOfBirth ? dayjs(dateOfBirth).format("YYYY-MM-DD") : undefined,
			issueBy,
			address,
		};

		const isValidFields = CitizenPassportValidationStore.checkValidation(validationPassportFields);

		if (!isValidFields) {
			return;
		}

		const documentBody: IDocumentCreate = {
			type: "PASSPORT",
			targetId: CitizensDrawerStore.selectedUser.id,
			sourceId: CitizensDrawerStore.selectedUser.id,
			registrationDate: "2023-04-04",
			placeOfBirth: "Москва",
			fileIds: newFileIds,
			...validationPassportFields,
		};

		if (citizenDocument) {
			await updateDocument.mutateAsync({
				body: documentBody,
				path: {
					id: citizenDocument.id,
				},
			});
		} else {
			await createDocument.mutateAsync({
				body: documentBody,
			});
		}

		refreshUser();
	};

	const citizenPassportInformation = [
		{
			title: "Cерия Номер",
			value: getPassportValue(seriesAndNumberOfPassport),
		},
		{
			title: "Код подразделения",
			value: getPassportValue(citizenDocument?.departmentCode),
		},
		{
			title: "Дата выдачи",
			value: getPassportValue(
				citizenDocument?.dateOfIssue ? dayjs(citizenDocument?.dateOfIssue).format("DD.MM.YYYY") : citizenDocument?.dateOfIssue,
			),
		},
		{
			title: "Дата рождения",
			value: getPassportValue(
				citizenDocument?.dateOfBirth ? dayjs(citizenDocument?.dateOfBirth).format("DD.MM.YYYY") : citizenDocument?.dateOfBirth,
			),
		},
		{
			title: "Кем выдан",
			value: getPassportValue(citizenDocument?.issueBy),
		},
		{
			title: "Адрес по прописке",
			value: getPassportValue(citizenDocument?.address),
		},
	];

	const citizenEditPasswordInformation = [
		{
			label: "Серия",
			value: series,
			placeholder: "00 00",
			onChange: (v) => onChangeInputBase(() => setSeries(v), "series"),
			mask: citizenCreateStoreInputMask.passportSeries,
			type: "input",
			key: "series",
		},
		{
			label: "Номер",
			value: number,
			placeholder: "000000",
			maxLength: citizenCreateStoreInputMaxLength.passportNumber,
			onChange: (v) => onChangeInputBase(() => setNumber(v), "number"),
			type: "input",
			key: "number",
		},
		{
			label: "Код подразделения",
			value: departmentCode,
			placeholder: "000-000",
			onChange: (v) => onChangeInputBase(() => setDepartmentCode(v), "departmentCode"),
			mask: "999-999",
			type: "input",
			key: "departmentCode",
		},
		{
			label: "Дата выдачи",
			value: dateOfIssue,
			placeholder: "01.01.2000",
			maxLength: editOrCreateCitizenInputMaxLength.dateOfIssue,
			onChange: (v) =>
				onChangeInputBase(() => {
					const date = v[0];
					setDateOfIssue(date === null ? undefined : date);
				}, "dateOfIssue"),
			type: "date",
			key: "dateOfIssue",
		},
		{
			label: "Дата рождения",
			value: dateOfBirth,
			placeholder: "01.01.2000",
			maxLength: editOrCreateCitizenInputMaxLength.dateOfBirth,
			onChange: (v) =>
				onChangeInputBase(() => {
					const date = v[0];
					setDateOfBirth(date === null ? undefined : date);
				}, "dateOfBirth"),
			type: "date",
			key: "dateOfBirth",
		},
		{
			label: "Кем выдан",
			value: issueBy,
			placeholder: "Орган выдавший паспорт",
			onChange: (v) => onChangeInputBase(() => setIssueBy(v), "issueBy"),
			type: "input",
			key: "issueBy",
		},
		{
			label: "Адрес по прописке",
			value: address,
			onChange: (v) => onChangeInputBase(() => setAddress(v), "address"),
			type: "textarea",
			key: "address",
		},
	];

	const passportValidation = CitizenPassportValidationStore.validationData;

	return (
		<Space width="100%" direction="column">
			<PersonalInformationWrapper>
				<CitizenDrawerTextHeaderBlock text="Паспорт" />
				{!props.onlyEditable && !isEdit && (
					<Button size="small" onClick={() => setIsEdit((status) => !status)} shape="square" typeButton="secondary" icon={EditIcon} />
				)}
			</PersonalInformationWrapper>
			{!isEdit && (
				<>
					<Space width="100%" direction="column">
						<CitizenRealtyWrapper>
							{citizenPassportInformation.map((v, idx, arr) => {
								const isLastElement = arr.length - 1 === idx;
								return (
									<>
										<CitizenWrapper>
											<Space align="start" size={1} direction="column">
												<Text color="rgba(18, 18, 18, 0.6)" type="table-cell-s-regular">
													{v.title}
												</Text>
												<Text color="rgba(18, 18, 18, 0.9)" type="table-cell-m-medium">
													{v.value}
												</Text>
											</Space>
										</CitizenWrapper>
										{!isLastElement && <CitizenRealtyDivider />}
									</>
								);
							})}
						</CitizenRealtyWrapper>
					</Space>
					{!!citizenDocument?.fileIds.length && <CitizenFiles disabled fileIds={citizenDocument?.fileIds} title="Изображение документа" />}
				</>
			)}
			{isEdit && (
				<>
					<CitizenPersonalEditWrapper background={props.onlyEditable ? colors.surfaceNeutralBg2 : undefined}>
						<CitizenPersonalEdit>
							{citizenEditPasswordInformation.map((info) => {
								const inputValidationStatus = info.key in passportValidation && passportValidation[info.key];
								const errorMessage = CitizenPassportValidationStore.getMessageIfError(info.key as keyof TCitizenPassportValidationFields);

								if (info.type === "input" || info.type === "textarea") {
									return (
										<UserEditInput
											mask={info.mask}
											label={info.label}
											required
											as={info.type}
											value={info.value as string}
											placeholder={info.placeholder}
											maxLength={info.mask ? undefined : info.maxLength}
											onChange={info.onChange as React.Dispatch<React.SetStateAction<string>>}
											isError={inputValidationStatus !== ValidationStatuses.VALID}
											message={errorMessage}
										/>
									);
								}
								if (info.type === "date") {
									return (
										<UserEditDate
											disabledDates={{ from: dayjs().toDate() }}
											label={info.label}
											required
											value={info.value ? dayjs(info.value) : undefined}
											placeholder="01.01.2000"
											onChange={info.onChange as React.Dispatch<React.SetStateAction<dayjs.Dayjs>>}
											isError={inputValidationStatus !== ValidationStatuses.VALID}
											message={errorMessage}
										/>
									);
								}
							})}

							<CitizenFiles
								fileIds={citizenDocument?.fileIds}
								needToRemoveFileIds={needToRemoveFileIds}
								setNeedToRemoveFileIds={setNeedToRemoveFileIds}
								newFiles={newFiles}
								setNewFiles={setNewFiles}
								title="Изображение документа"
							/>
						</CitizenPersonalEdit>

						{!props.onlyEditable && (
							<CitizenPersonalEditFooter>
								<Button
									typeButton="secondary"
									onClick={() => {
										setIsEdit(false);
										CitizenPassportValidationStore.resetValidation();
									}}>
									Отменить
								</Button>
								<Button onClick={updateDocuments}>Сохранить</Button>
							</CitizenPersonalEditFooter>
						)}
					</CitizenPersonalEditWrapper>
				</>
			)}
		</Space>
	);
};

export default observer(CitizenPassport);
