import { IClient, TClientContactsType } from "interfaces/IClient";
import { TDrawerMode } from "interfaces/IDrawerMode";
import { makeAutoObservable } from "mobx";

const defaultUser: IClient = {
	firstName: "",
	lastName: "",
	middleName: "",
	avatarBackground: "",
	avatarId: "",
	birthDate: "",
	contacts: [],
	deathDate: "",
	fullName: "",
	gender: null,
	id: null,
	inn: null,
	insuranceNumber: "",
	personType: "LEGAL",
	spaceId: "",
	residentialAddress: "",
	lastActivity: undefined,
	registration: false,
};

class CitizensDrawerStore {
	isVisibleDrawer = false;
	drawerMode: TDrawerMode | undefined = undefined;
	selectedUser: IClient = defaultUser;
	selectedUserBuildings = [];

	constructor() {
		makeAutoObservable(this);
	}

	openDrawer = (args: { mode: TDrawerMode; user?: IClient }) => {
		this.drawerMode = args.mode;
		this.selectedUser = args.user ?? defaultUser;
		this.isVisibleDrawer = true;
	};

	changeSelectedUser = ({ user }: { user?: IClient }) => {
		this.selectedUser = user;
	};

	closeDrawer = () => {
		this.drawerMode = undefined;
		this.selectedUser = defaultUser;
		this.isVisibleDrawer = false;
	};

	changeSelectedUserBuildings = (value: any) => {
		this.selectedUserBuildings = value;
	};

	changeUserInfo = (args: { key: keyof IClient; value: string | string[] }) => {
		if (typeof args.value === "boolean") {
			// return;
		}

		// (this.selectedUser[args.key] as IClient[keyof IClient]) = args.value; // TODO
	};

	addNewContact = (type: TClientContactsType, meta?: { isDefault?: boolean }) => {
		this.selectedUser.contacts = [
			...this.selectedUser.contacts,
			{ description: "", isDefault: meta?.isDefault ?? this.selectedUser.contacts.length === 0, type, value: "" },
		];
	};

	removeContact = (idx: number) => {
		this.selectedUser.contacts = [...this.selectedUser.contacts.slice(0, idx), ...this.selectedUser.contacts.slice(idx + 1)];
	};

	changeUserContects = (args: {
		type: TClientContactsType;
		value: string;
		idx: number;
		variant: "input" | "description";
		meta?: { isDefault: boolean; description?: string };
	}) => {
		let currentContact = this.selectedUser.contacts[args.idx];

		if (!currentContact) {
			currentContact = {
				description: "",
				id: "",
				isDefault: false,
				type: args.type,
				value: "",
			};
		}

		if (args.variant === "input") {
			currentContact.value = args.value;
			currentContact.isDefault = !!args.meta?.isDefault;
			currentContact.description = args.meta.description;
		}

		if (args.variant === "description") {
			currentContact.description = args.value;
		}

		this.selectedUser.contacts[args.idx] = currentContact;
	};
}

export default new CitizensDrawerStore();
